import * as yup from "yup";
import { FormComponent } from "../../../interfaces/FormComponent";
import { FormStructure, Option } from "../../../interfaces/FormStructure";
import MedicationType from "../../../interfaces/MedicationType";
import { MedicationEditPageType } from "../../../interfaces/Pages";

// eslint-disable-next-line react-hooks/rules-of-hooks
import i18next from "i18next";

export const medicationFormStructure = {
  components: [
    {
      component: FormComponent.TextField,
      label: i18next.t("medicationFormStructure.name.label"),
      name: "name",
      type: "text",
    },
    {
      component: FormComponent.Select,
      label: i18next.t("medicationFormStructure.frequency.label"),
      type: "number",
      name: "frequency",
      values: Array.from({ length: 30 }, (_, i) => i + 1),
    },
    {
      component: FormComponent.DatePicker,
      label: i18next.t("medicationFormStructure.time.date"),
      name: "startingDate",
      disablePast: true,
      defaultVal: new Date().toISOString(),
      type: "date",
    },
    {
      component: FormComponent.TimePicker,
      label: i18next.t("medicationFormStructure.time.label"),
      name: "time",
      defaultVal: new Date(),
      type: "date",
    },
  ],
  createTitle: i18next.t("medicationFormStructure.createTitle"),
  editTitle: i18next.t("medicationFormStructure.editTitle"),
  deleteTitle: i18next.t("medicationFormStructure.deleteTitle"),
} as FormStructure;

export const getEditMedicationFormStructure = (
  medication: MedicationType
): FormStructure => {
  return {
    components: [
      {
        component: FormComponent.TextField,
        label: i18next.t("medicationFormStructure.name.label"),
        name: "field_medication_label",
        type: "text",
        defaultValue: medication?.name,
        placeholder: " ",
      },
      {
        component: FormComponent.SelectDropdown,
        label: i18next.t("medicationFormStructure.frequency.label"),
        type: "text",
        name: "field_medication_frequency",
        defaultValue: medication?.frequency,
        options: Array.from({ length: 30 }, (_, i) => i + 1).map(
          (n) => ({ id: n.toString(), label: n.toString() } as Option)
        ),
      },
      {
        component: FormComponent.DatePicker,
        label: i18next.t("medicationFormStructure.date.label"),
        name: "field_medication_starting_date",
        disablePast: true,
        type: "text",
        defaultValue: medication?.startingDate,
      },
      {
        component: FormComponent.TimePicker,
        label: i18next.t("medicationFormStructure.time.label"),
        name: "field_medication_starting_date",
        type: "text",
        defaultValue: medication?.startingDate,
      },
    ],
    schema: yup.object().shape({
      field_medication_label: yup
        .string()
        .required(i18next.t("error.required.medication")),
      field_medication_frequency: yup
        .string()
        .required("Frequency is required"),
      field_medication_starting_date: yup
        .date()
        .required(i18next.t("error.required.date")),
    }),
  };
};

export const getCreateMedicationFormStructure = (
  content: MedicationEditPageType
) => {
  return {
    components: [
      {
        component: FormComponent.TextField,
        label: i18next.t("medicationFormStructure.name.label"),
        name: "field_medication_label",
        type: "text",
        placeholder: " ",
      },
      {
        component: FormComponent.SelectDropdown,
        label: i18next.t("medicationFormStructure.frequency.label"),
        type: "number",
        name: "field_medication_frequency",
        options: Array.from({ length: 30 }, (_, i) => i + 1).map(
          (n) => ({ id: n.toString(), label: n.toString() } as Option)
        ),
      },
      {
        component: FormComponent.DatePicker,
        label: i18next.t("medicationFormStructure.date.label"),
        name: "field_medication_starting_date",
        disablePast: true,
        type: "text",
        noAnimation: true,
        defaultValue: new Date(),
      },
      {
        component: FormComponent.TimePicker,
        label: i18next.t("medicationFormStructure.time.label"),
        name: "field_medication_starting_date",
        type: "text",
        noAnimation: true,
        defaultValue: new Date(),
      },
    ],
    schema: yup.object().shape({
      field_medication_label: yup
        .string()
        .required(i18next.t("error.required.medication")),
      field_medication_frequency: yup
        .string()
        .required("Frequency is required"),
      field_medication_starting_date: yup
        .date()
        .required(i18next.t("error.required.date")),
    }),
  };
};
