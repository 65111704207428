import React from "react";
import { AuthLink } from "../../routing/AuthLink";
import { noop, sanitizeURL } from "../../util/util";

const MenuItem = ({ url, roles, label }) => {
  return (
    <div className="menu-item">
      <AuthLink to={sanitizeURL(url)} roles={roles} onClose={noop}>
        {label}
      </AuthLink>
    </div>
  );
};

export default MenuItem;
