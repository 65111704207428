import axios, { AxiosRequestConfig } from "axios";
import { getUserData } from "../context/AuthContext";
import { Interval } from "../interfaces/Interval";
import { PageType } from "../interfaces/Pages";
import { logout } from "../store/AuthActions";
import { store } from "../store/Reducer";
import {
  finishedLoading,
  loading,
  TOOLBOX_RESPONSE_UPDATE,
} from "../store/ToolBoxActions";

const baseUrl = process.env.REACT_APP_BASE_URL || "";
const client_secret = process.env.REACT_APP_CLIENT_SECRET || "";
const client_id = process.env.REACT_APP_CLIENT_ID;

const FormData = require("form-data");

axios.defaults.headers.post["Content-Type"] = "application/json";

function sendRequest(
  config: AxiosRequestConfig,
  isForm = false,
  login = false,
  needCSRF = false
) {
  const token = getUserData().access_token;

  config.headers = {};

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  if (needCSRF) {
    config.headers["x-csrf-token"] = store.getState()?.auth.user?.csrfToken;
  }

  if (config.method === "post" && !config.data) {
    config.data = {};
  }

  return axios(config)
    .then(function (response) {
      if (
        response.data.success ||
        response.data?.data?.content?.content === "[]" ||
        response.data?.nid
      ) {
        store.dispatch({
          type: TOOLBOX_RESPONSE_UPDATE,
          msg: response.data.message || response.statusText,
        });
        return response.data;
      } else if (login) {
        return response.data;
      } else if (response.data && response.data.data?.content) {
        store.dispatch({
          type: TOOLBOX_RESPONSE_UPDATE,
          error: response.data.data.content,
          msg: response.data.message,
        });
        throw new Error(response.data.data.content);
      } else if (response.data || !response.data.success) {
        if (isForm) {
          store.dispatch({
            type: TOOLBOX_RESPONSE_UPDATE,
            formErrors: response.data.data,
          });
          throw new Error(response.data.data);
        } else {
          store.dispatch({
            type: TOOLBOX_RESPONSE_UPDATE,
            error: response.data.message,
          });
          throw new Error(response.data.message);
        }
      } else {
        store.dispatch({
          type: TOOLBOX_RESPONSE_UPDATE,
          error: response.statusText,
        });
        throw new Error(response.statusText);
      }
    })
    .catch((error) => {
      store.dispatch(finishedLoading(PageType.general, error, false));
      if (error.response?.status === 401 && !login) {
        store.dispatch(logout(error));
      } else if (error.response?.status === 422) {
        store.dispatch({
          type: TOOLBOX_RESPONSE_UPDATE,
          error: error.response?.data?.message,
        });
        throw new Error(error.message);
      } else if (login) {
        throw new Error(error.response?.data?.message);
      } else {
        throw new Error(error.message);
      }
    });
}

function sendPageContentRequest(
  config: AxiosRequestConfig,
  isForm = false,
  login = false
) {
  const token = getUserData().access_token;

  config.headers = {
    "Content-Type": "application/json",
  };

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  if (config.method === "post" && !config.data) {
    config.data = {};
  }

  return axios(config)
    .then(function (resp) {
      return resp.data;
    })
    .catch((error) => {
      if (
        error.response?.status === 401 &&
        !login &&
        store.getState().auth.authenticated
      ) {
        store.dispatch(logout(error));
      } else if (login) {
        throw new Error(error.response?.data?.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export class API {
  login(userData: any) {
    const data = new FormData();
    data.append("grant_type", "password");
    data.append("client_id", client_id);
    data.append("client_secret", client_secret);
    data.append("username", userData.name);
    data.append("password", userData.pass);

    let config = {
      method: "post" as "post",
      url: `${baseUrl}/oauth/token`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    return sendRequest(config, false, true);
  }

  registerHCP(data: any) {
    var config = {
      method: "post" as "post",
      url: `${baseUrl}/api/json/hcp/register?_format=json`,
      data: data,
    };

    return sendRequest(config, true);
  }

  registerPatient(data: Object) {
    var config = {
      method: "post" as "post",
      url: `${baseUrl}/api/json/patient/register?_format=json`,
      data: data,
    };

    return sendRequest(config, true);
  }

  placeCoolbagOrder(data) {
    const config = {
      method: "post" as "post",
      url: `${baseUrl}/place-order?_format=json`,
      data: data,
    };

    return sendRequest(config, true);
  }

  getCoolbagOrder() {
    var config = {
      method: "get" as "get",
      url: `${baseUrl}/coolbag-order-rest-api/get-order?_format=json`,
    };

    store.dispatch(loading(PageType.coolbag));

    return sendRequest(config);
  }

  getUserConditions() {
    let config = {
      method: "get" as "get",
      url: `${baseUrl}/api/json/get-conditions?_format=json`,
    };

    return sendRequest(config);
  }

  logout() {
    let config = {
      method: "get" as "get",
      url: `${baseUrl}/user/logout?_format=json`,
    };

    return sendRequest(config);
  }

  getUserDailySymptom(interval) {
    var config = {
      method: "get" as "get",
      url: `${baseUrl}/daily-user-symptom-rest-api/get-daily-user-symptoms?_format=json&interval=${interval}`,
    };

    sendRequest(config);
  }

  getMedications() {
    const config = {
      method: "get" as "get",
      url: `${baseUrl}/medication-rest-api/get-medications?_format=json`,
    };

    return sendRequest(config);
  }

  getGoals() {
    const config = {
      method: "get" as "get",
      url: `${baseUrl}/goal-rest-api/get-goals?_format=json`,
    };

    return sendRequest(config);
  }

  getSymptoms() {
    const config = {
      method: "get" as "get",
      url: `${baseUrl}/symptom-rest-api/get-symptoms?_format=json`,
    };

    return sendRequest(config);
  }

  getAccessCodeType(data: string) {
    const config = {
      method: "get" as "get",
      url: `${baseUrl}/access-code/get-type?_format=json&access_code=${data}`,
    };

    return sendRequest(config);
  }

  createSymptom(data) {
    const config = {
      method: "post" as "post",
      url: `${baseUrl}/symptom-rest-api/create-symptom?_format=json`,
      data: data,
    };

    return sendRequest(config);
  }

  modifySymptom(data) {
    const config = {
      method: "post" as "post",
      url: `${baseUrl}/symptom-rest-api/${data.id[0].value}/modify-symptom?_format=json`,
      data: data,
    };

    return sendRequest(config);
  }

  deleteSymptom(data) {
    const config = {
      method: "post" as "post",
      url: `${baseUrl}/symptom-rest-api/${data}/delete-symptom?_format=json`,
    };

    return sendRequest(config);
  }

  createMedication(data) {
    const config = {
      method: "post" as "post",
      url: `${baseUrl}/medication-rest-api/create-medication?_format=json`,
      data: data,
    };

    return sendRequest(config, true);
  }

  modifyMedication(data) {
    const { id, ...newData } = data;

    const config = {
      method: "post" as "post",
      url: `${baseUrl}/medication-rest-api/${data.id[0].value}/modify-medication?_format=json`,
      data: newData,
    };

    return sendRequest(config, true);
  }

  deleteMedication(data) {
    const config = {
      method: "post" as "post",
      url: `${baseUrl}/medication-rest-api/${data}/delete-medication?_format=json`,
    };

    return sendRequest(config);
  }

  createGoal(data) {
    var config = {
      method: "post" as "post",
      url: `${baseUrl}/goal-rest-api/create-goal?_format=json`,
      data: data,
    };

    return sendRequest(config);
  }

  modifyGoal(data) {
    const { id, ...newData } = data;

    var config = {
      method: "post" as "post",
      url: `${baseUrl}/goal-rest-api/${data.id[0].value}/modify-goal?_format=json`,
      data: newData,
    };

    return sendRequest(config);
  }

  deleteGoal(data) {
    var config = {
      method: "post" as "post",
      url: `${baseUrl}/goal-rest-api/${data}/delete-goal?_format=json`,
    };

    return sendRequest(config);
  }

  createNote(data) {
    var config = {
      method: "post" as "post",
      url: `${baseUrl}/notes-rest-api/create-note?_format=json`,
      data: data,
    };

    return sendRequest(config);
  }

  createAppointment(data) {
    var config = {
      method: "post" as "post",
      url: `${baseUrl}/notes-rest-api/create-note?type=appointment&_format=json`,
      data: data,
    };

    return sendRequest(config);
  }

  modifyNote(data) {
    const { id, ...newData } = data;

    var config = {
      method: "post" as "post",
      url: `${baseUrl}/notes-rest-api/${data.id[0].value}/modify-note?_format=json`,
      data: newData,
    };

    return sendRequest(config);
  }

  deleteNote(data) {
    var config = {
      method: "post" as "post",
      url: `${baseUrl}/notes-rest-api/${data}/delete-note?_format=json`,
    };

    return sendRequest(config);
  }

  getNotes() {
    const config = {
      method: "get" as "get",
      url: `${baseUrl}/notes-rest-api/get-notes?type=notes&_format=json`,
    };

    return sendRequest(config);
  }

  getAppointments() {
    const config = {
      method: "get" as "get",
      url: `${baseUrl}/notes-rest-api/get-notes?type=appointments&_format=json`,
    };

    return sendRequest(config);
  }

  createDailyUserSymptom(data) {
    const config = {
      method: "post" as "post",
      url: `${baseUrl}/daily-user-symptom-rest-api/create-daily-user-symptoms?_format=json`,
      data: data,
    };

    return sendRequest(config);
  }

  createMedicationReport(data) {
    const config = {
      method: "post" as "post",
      url: `${baseUrl}/user-medication-report-rest-api/create-user-medication-report?_format=json`,
      data: data,
    };

    return sendRequest(config, true);
  }

  forgotPassword(data) {
    const config = {
      method: "post" as "post",
      url: `${baseUrl}/forgot-my-password?_format=json`,
      data: data,
    };

    return sendRequest(config);
  }

  changePassword(data) {
    let newData: {};

    if (!data.hasOwnProperty("title")) {
      const title = [
        {
          value: `Email change request for ${data.field_email[0].value}`,
        },
      ];
      newData = { ...data, title };
    } else {
      newData = data;
    }

    const config = {
      method: "post" as "post",
      url: `${baseUrl}/change-password?_format=json`,
      data: newData,
    };

    return sendRequest(config, true);
  }

  getUserMedicationReport() {
    const config = {
      method: "get" as "get",
      url: `${baseUrl}/user-medication-report-rest-api/get-user-medication-report?_format=json`,
    };

    return sendRequest(config);
  }

  getPageContent(page: PageType) {
    const config = {
      method: "get" as "get",
      url: `${baseUrl}/page-content-rest-api/get-page-content?_format=json&page=${page}`,
    };

    return sendRequest(config);
  }

  modifyProfile(data) {
    const config = {
      method: "post" as "post",
      url: `${baseUrl}/profile-edit-rest-api/modify-profile?_format=json`,
      data: data,
    };

    return sendRequest(config, true);
  }

  getFlare(month: Interval) {
    const config = {
      method: "get" as "get",
      url: `${baseUrl}/flare-rest-api/get-flares?_format=json&interval=${month}`,
    };

    return sendRequest(config);
  }

  createFlare(flare: Object) {
    const config = {
      method: "post" as "post",
      url: `${baseUrl}/flare-rest-api/create-flare?_format=json`,
      data: flare,
    };

    return sendRequest(config);
  }

  getReminder() {
    const config = {
      method: "get" as "get",
      url: `${baseUrl}/tool-reminder-rest-api/get-tool-reminder`,
    };

    return sendRequest(config);
  }

  createReminder(data) {
    const config = {
      method: "post" as "post",
      url: `${baseUrl}/tool-reminder-rest-api/create-tool-reminder?_format=json`,
      data: data,
    };

    return sendRequest(config);
  }

  modifyReminder(data) {
    const config = {
      method: "post" as "post",
      url: `${baseUrl}/tool-reminder-rest-api/modify-tool-reminder?_format=json`,
      data: data,
    };

    return sendRequest(config, true);
  }

  deleteReminder() {
    const config = {
      method: "post" as "post",
      url: `${baseUrl}/tool-reminder-rest-api/delete-tool-reminder?_format=json`,
    };

    return sendRequest(config, true);
  }

  getCoachingTool() {
    const config = {
      method: "get" as "get",
      url: `${baseUrl}/coaching-tool-rest-api/get-coaching?_format=json`,
    };

    return sendRequest(config, true);
  }

  getPage(page) {
    const config = {
      method: "get" as "get",
      url: `${baseUrl}/en/rest/${page}?_format=json`,
    };

    return sendPageContentRequest(config, true);
  }

  getNodeByTitle(title, mobile = false, type = "chrons_disease_page") {
    const config = {
      method: "get" as "get",
      url: `${baseUrl}/admin/content/rest/?_format=json&title=${title}&type=${type}&is_mobile=${
        mobile ? "1" : "0"
      }`,
    };

    return sendPageContentRequest(config, true);
  }

  getNodeByType(type) {
    const config = {
      method: "get" as "get",
      url: `${baseUrl}/admin/content/rest/?_format=json&type=${type}`,
    };

    return sendPageContentRequest(config, true);
  }

  getNode(nodes) {
    const config = {
      method: "get" as "get",
      url: `${baseUrl}/admin/content/rest/${nodes}?_format=json`,
    };

    return sendPageContentRequest(config, true);
  }

  getMedia(nodes) {
    const config = {
      method: "get" as "get",
      url: `${baseUrl}/admin/content/media/${nodes}?_format=json`,
    };

    return sendPageContentRequest(config, true);
  }

  getCurrentUser() {
    const config = {
      method: "get" as "get",
      url: `${baseUrl}/rest/current_user?_format=json`,
    };

    return sendPageContentRequest(config, true);
  }

  getTitles() {
    const config = {
      method: "get" as "get",
      url: `${baseUrl}/page-content-rest-api/get-page-content?_format=json&page=hcp-contact-form-name-titles`,
    };

    return sendPageContentRequest(config, true);
  }

  getQuestionTypes() {
    const config = {
      method: "get" as "get",
      url: `${baseUrl}/page-content-rest-api/get-page-content?_format=json&page=hcp-contact-form-question-types`,
    };

    return sendPageContentRequest(config, true);
  }

  getKitTypes() {
    const config = {
      method: "get" as "get",
      url: `${baseUrl}/page-content-rest-api/get-page-content?_format=json&page=hcp-kit-order-form-kit-types`,
    };

    return sendPageContentRequest(config, true);
  }

  postForm(body) {
    const config = {
      method: "post" as "post",
      url: `${baseUrl}/node?_format=json`,
      data: body,
      xsrfHeaderName: "x-csrf-token",
    };

    return sendRequest(config, true, false, true);
  }

  getCsrfToken() {
    const config = {
      method: "post" as "post",
      url: `${baseUrl}/session/token?_format=json`,
    };

    return sendPageContentRequest(config, true);
  }

  getUserInfo(userId) {
    const config = {
      method: "get" as "get",
      url: `${baseUrl}/user/${userId}?_format=json`,
    };

    return sendPageContentRequest(config, true);
  }
}

export const Api = new API();
