import React, {lazy} from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import ErrorBoundaryPage from "../component/ErrorBoundary/ErrorBoundaryPage";
import {homePage} from "../constants/constants";
import {ToolBoxDataType} from "../interfaces/ToolBoxData";
import {CoachingToolPage} from "../Pages/CoachingTool.tsx/CoachingTool";
import ContactForm from "../Pages/ContactForm/ContactForm";
import ThankYouPage from "../Pages/Coolbag/ThankYouPage";
import {DynamicPage} from "../Pages/DynamicPage/DynamicPage";
import ForgotPasswordPage from "../Pages/ForgotPassword/ForgotPasswordPage";
import {HomePage} from "../Pages/Home/HomePage";
import ChangePassword from "../Pages/ChangePassword/ChangePassword";
import Login from "../Pages/Login/Login";
import {NotFoundPage} from "../Pages/NotFound/NotFoundPage";
import OrderKitForm from "../Pages/OrderKitForm/OrderKitForm";
import RegisterFormHCP from "../Pages/Register/RegisterFormHCP";
import RegisterFormMain from "../Pages/Register/RegisterFormMain";
import RegisterFormPatient from "../Pages/Register/RegisterFormPatient";
import ThankYouForRegisteringPage from "../Pages/Register/ThankYouForRegisteringPage";
import {GoalSettingWrapper} from "../Pages/Toolbox/Goal/GoalSettingsWrapper";
import {ReminderPage} from "../Pages/Toolbox/Reminder/ReminderPage";
import ToolBoxSettingsPage from "../Pages/Toolbox/ToolBoxSettingsPage";
import {YourConditionPage} from "../Pages/YourCondition.tsx/YourConditionPage";
import PrivateRoute from "./PrivateRoute";
import {Restriction} from "../interfaces/Restriction";
import MyProgressPage from "../Pages/Toolbox/MyProgress/MyProgressPage";

const AccountPage = lazy(() => import("../Pages/Profile/ProfilePage"));
const CoolbagForm = lazy(() => import("../Pages/Coolbag/CoolbagForm"));

export const Routes: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <ErrorBoundaryPage>
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

        {/*IDACIO*/}
        <PrivateRoute path="/my-idacio/understanding-treatment" restriction={Restriction.IDACIO}>
          <DynamicPage/>
        </PrivateRoute>

        <PrivateRoute path="/my-idacio/how-to-inject-idacio" restriction={Restriction.IDACIO}>
          <DynamicPage/>
        </PrivateRoute>

        <PrivateRoute path="/my-idacio/caring-for-your-medication" restriction={Restriction.IDACIO}>
          <DynamicPage/>
        </PrivateRoute>

        <PrivateRoute path="/my-idacio/help-and-support" restriction={Restriction.IDACIO}>
          <DynamicPage/>
        </PrivateRoute>

        <PrivateRoute exact path="/my-idacio/my-progress/goals/settings" restriction={Restriction.IDACIO}>
          <GoalSettingWrapper editPageUrl="/my-idacio/my-progress/goals/settings/goal/" />
        </PrivateRoute>

        <PrivateRoute path="/my-idacio/my-progress/goals" restriction={Restriction.IDACIO}>
          <GoalSettingWrapper editPageUrl="/my-idacio/my-progress/goals/settings/goal/" />
        </PrivateRoute>

        <Redirect
            from="/my-idacio/my-progress/goals"
            to="/my-idacio/my-progress/goals/settings"
        />

        <PrivateRoute
            exact
            path="/my-idacio/my-progress/log-medication/settings"
            restriction={Restriction.IDACIO}>
          <ToolBoxSettingsPage
              dataType={ToolBoxDataType.MEDICATIONS}
              editPageUrl="/my-idacio/my-progress/log-medication/settings/medication/"
              showTime={true}
              showDate={true}
              isMedication={true}
          />
        </PrivateRoute>

        <PrivateRoute path="/my-idacio/my-progress/reminders" restriction={Restriction.IDACIO}>
          <ReminderPage />
        </PrivateRoute>

        <PrivateRoute exact path="/my-idacio/my-progress" restriction={Restriction.IDACIO}>
          <MyProgressPage
            goalsLink="/my-idacio/my-progress/goals/settings"
            medicationsLink="/my-idacio/my-progress/log-medication/settings"
            reminderLink="/my-idacio/my-progress/reminders"/>
        </PrivateRoute>

        <PrivateRoute path="/my-idacio/coolbag/thank-you" restriction={Restriction.IDACIO}>
          <ThankYouPage />
        </PrivateRoute>

        <PrivateRoute path="/my-idacio/coolbag" restriction={Restriction.IDACIO}>
          <CoolbagForm />
        </PrivateRoute>

        <PrivateRoute path="/my-idacio" restriction={Restriction.IDACIO}>
          <DynamicPage />
        </PrivateRoute>
        {/*IDACIO END*/}

        {/*TYENNE*/}
        <PrivateRoute path="/my-tyenne/understanding-tyenne" restriction={Restriction.TYENNE}>
          <DynamicPage />
        </PrivateRoute>

        <PrivateRoute path="/my-tyenne/how-to-inject-tyenne" restriction={Restriction.TYENNE}>
          <DynamicPage />
        </PrivateRoute>

        <PrivateRoute path="/my-tyenne/caring-for-your-tyenne" restriction={Restriction.TYENNE}>
          <DynamicPage />
        </PrivateRoute>

        <PrivateRoute path="/my-tyenne/help-and-supports" restriction={Restriction.TYENNE}>
          <DynamicPage />
        </PrivateRoute>

        <PrivateRoute path="/my-tyenne/my-progress/goals" restriction={Restriction.TYENNE}>
          <GoalSettingWrapper editPageUrl="/my-tyenne/my-progress/goals/settings/goal/" />
        </PrivateRoute>

        <PrivateRoute exact path="/my-tyenne/my-progress/goals/settings" restriction={Restriction.TYENNE}>
          <GoalSettingWrapper editPageUrl="/my-tyenne/my-progress/goals/settings/goal/" />
        </PrivateRoute>

        <Redirect
            from="/my-tyenne/my-progress/goals"
            to="/my-tyenne/my-progress/goals/settings"
        />

        <PrivateRoute
            exact
            path="/my-tyenne/my-progress/log-medication/settings"
            restriction={Restriction.TYENNE}>
          <ToolBoxSettingsPage
              dataType={ToolBoxDataType.MEDICATIONS}
              editPageUrl="/my-tyenne/my-progress/log-medication/settings/medication/"
              showTime={true}
              showDate={true}
              isMedication={true}
          />
        </PrivateRoute>

        <PrivateRoute path="/my-tyenne/my-progress/reminders" restriction={Restriction.TYENNE}>
          <ReminderPage />
        </PrivateRoute>

        <PrivateRoute exact path="/my-tyenne/my-progress" restriction={Restriction.TYENNE}>
          <MyProgressPage
            goalsLink="/my-tyenne/my-progress/goals/settings"
            medicationsLink="/my-tyenne/my-progress/log-medication/settings"
            reminderLink="/my-tyenne/my-progress/reminders"/>
        </PrivateRoute>

        <PrivateRoute path="/my-tyenne/request-a-coolbag/thank-you" restriction={Restriction.TYENNE}>
          <ThankYouPage />
        </PrivateRoute>

        <PrivateRoute path="/my-tyenne/request-a-coolbag" restriction={Restriction.TYENNE}>
          <CoolbagForm />
        </PrivateRoute>

        <PrivateRoute path="/my-tyenne" restriction={Restriction.TYENNE}>
          <DynamicPage />
        </PrivateRoute>
        {/*TYENNE END*/}

        <Route path={"/" + homePage}>
          <HomePage />
        </Route>
        <Route path="/login/:userType">
          <Login />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/register">
          <RegisterFormMain />
        </Route>
        <Route path="/forgot-password">
          <ForgotPasswordPage />
        </Route>
        <Route path="/change-password">
          <ChangePassword />
        </Route>
        <Route path="/hcp/register">
          <RegisterFormHCP />
        </Route>
        <Route path="/hcp/thank-you">
          <ThankYouForRegisteringPage breadcrumb="For HCPs" />
        </Route>
        <Route path="/patient/register">
          <RegisterFormPatient />
        </Route>
        <Route path="/patient/thank-you">
          <ThankYouForRegisteringPage breadcrumb="For patients" />
        </Route>
        <PrivateRoute path="/profile">
          <AccountPage />
        </PrivateRoute>
        <Route path="/wellbeing-and-support/your-wellbeing/coaching-tool">
          <CoachingToolPage />
        </Route>
        <Route exact path="/getting-started/your-condition">
          <YourConditionPage />
        </Route>
        <Route path="/not-found">
          <NotFoundPage />
        </Route>
        <Route exact path="/">
          <Redirect to={homePage} />
        </Route>
        <PrivateRoute exact path="/hcp-access/make-a-request/request-a-rep-call" restriction={Restriction.HCP}>
          <ContactForm />
        </PrivateRoute>
        <PrivateRoute exact path="/hcp-access/request-a-rep-call" restriction={Restriction.HCP}>
          <ContactForm />
        </PrivateRoute>
        <PrivateRoute exact path="/hcp-access/make-a-request/order-a-kit-idacio" restriction={Restriction.HCP}>
          <OrderKitForm />
        </PrivateRoute>
        <PrivateRoute exact path="/hcp-access/make-a-request/order-a-kit-tyenne" restriction={Restriction.HCP}>
          <OrderKitForm />
        </PrivateRoute>
        <PrivateRoute path="/hcp-access" restriction={Restriction.HCP}>
          <DynamicPage />
        </PrivateRoute>
        <PrivateRoute path="/my-treatment" restriction={Restriction.PATIENT}>
          <DynamicPage />
        </PrivateRoute>
        <Route path="">
          <DynamicPage />
        </Route>
      </Switch>
    </ErrorBoundaryPage>
  );
};