import { UserLoginData } from "../context/AuthContext";

export const LOGIN = "login";
export const UPDATE_USER = "update_user";
export const UPDATE_CONDITIONS = "update_conditions";
export const GET_CONDITIONS = "get_conditions";
export const GET_USER = "get_user";
export const LOGOUT = "logout";

interface AuthAction {
  type:
    | typeof LOGIN
    | typeof UPDATE_USER
    | typeof GET_USER
    | typeof UPDATE_CONDITIONS
    | typeof GET_CONDITIONS
    | typeof LOGOUT;
  userData?: UserLoginData;
  msg?: string;
  conditions?: Array<string>;
}

export type AuthActionTypes = AuthAction;

export function login(userData: UserLoginData): AuthAction {
  return {
    type: LOGIN,
    userData: userData,
  };
}

export function logout(msg: string): AuthAction {
  return {
    type: LOGOUT,
    msg: msg,
  };
}

export function updateCurrentUser(userData): AuthAction {
  return {
    type: UPDATE_USER,
    userData: userData,
  };
}

export function updateConditions(conditions): AuthAction {
  return {
    type: UPDATE_CONDITIONS,
    conditions: conditions,
  };
}

export function getCurrentUser(): AuthAction {
  return {
    type: GET_USER,
  };
}

export function getConditions(): AuthAction {
  return {
    type: GET_CONDITIONS,
  };
}
