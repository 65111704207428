import { PageType } from "../interfaces/Pages";
import { ToolBoxData, ToolBoxDataType } from "../interfaces/ToolBoxData";
import { store } from "./Reducer";

export const TOOLBOX_FETCH_SUCCEEDED = "TOOLBOX_FETCH_SUCCEEDED";
export const TOOLBOX_FETCH_FAILED = "TOOLBOX_FETCH_FAILED";
export const TOOLBOX_FETCH_REQUESTED = "TOOLBOX_FETCH_REQUESTED";
export const CONDITION_FETCH_REQUESTED = "CONDITION_FETCH_REQUESTED";
export const TOOLBOX_DATA_UPDATE = "TOOLBOX_DATA_UPDATE";
export const TOOLBOX_RESPONSE_UPDATE = "TOOLBOX_RESPONSE_UPDATE";
export const LOADING_FINISHED = "LOADING_FINISHED";
export const LOADING_STARTED = "LOADING_STARTED";
export const LOADING_REMOVE_SUCCESS = "LOADING_REMOVE_SUCCESS";
export const LOADING_REMOVE_ALL_SUCCESS = "LOADING_REMOVE_ALL_SUCCESS";
export const TOOLBOX_EDIT = "TOOLBOX_EDIT";
export const TOOLBOX_DELETE = "TOOLBOX_DELETE";
export const TOOLBOX_CREATE = "TOOLBOX_CREATE";
export const CLEAR_FORM_ERRORS = "CLEAR_FORM_ERRORS";

export interface ToolBoxAction {
  type:
    | typeof TOOLBOX_FETCH_SUCCEEDED
    | typeof TOOLBOX_FETCH_FAILED
    | typeof TOOLBOX_FETCH_REQUESTED
    | typeof CONDITION_FETCH_REQUESTED
    | typeof TOOLBOX_DATA_UPDATE
    | typeof TOOLBOX_RESPONSE_UPDATE
    | typeof TOOLBOX_CREATE
    | typeof TOOLBOX_EDIT
    | typeof TOOLBOX_DELETE
    | typeof CLEAR_FORM_ERRORS;
  data?: ToolBoxData | any;
  dataType?: ToolBoxDataType;
  msg?: string;
  error?: string;
  formErrors?: Object;
}

export interface ToolBoxResponseAction {
  type: typeof TOOLBOX_RESPONSE_UPDATE;
  msg: string;
  error: string;
}

export interface LoadingAction {
  type:
    | typeof LOADING_FINISHED
    | typeof LOADING_STARTED
    | typeof LOADING_REMOVE_SUCCESS
    | typeof LOADING_REMOVE_ALL_SUCCESS;
  success?: boolean;
  msg?: string;
  page: string;
}

export function saveToolBox(data: ToolBoxData): ToolBoxAction {
  return {
    type: TOOLBOX_FETCH_SUCCEEDED,
    data: data,
  };
}

export function failedRequest(data: ToolBoxData): ToolBoxAction {
  return {
    type: TOOLBOX_FETCH_SUCCEEDED,
    data: data,
  };
}

export function updateData(data: ToolBoxData): ToolBoxAction {
  return {
    type: TOOLBOX_DATA_UPDATE,
    data: data,
  };
}

export function editToolBox(
  data: Object,
  type: ToolBoxDataType
): ToolBoxAction {
  return {
    type: TOOLBOX_EDIT,
    data: data,
    dataType: type,
  };
}

export function deleteFromToolBox(
  data: number,
  type: ToolBoxDataType
): ToolBoxAction {
  return {
    type: TOOLBOX_DELETE,
    data: data,
    dataType: type,
  };
}

export function addToToolBox(
  data: Object,
  type: ToolBoxDataType
): ToolBoxAction {
  return {
    type: TOOLBOX_CREATE,
    data: data,
    dataType: type,
  };
}

export function loading(page: string): LoadingAction {
  return {
    type: LOADING_STARTED,
    page: page,
  };
}

export function finishedLoading(
  page: string,
  msg: string,
  success: boolean
): LoadingAction {
  setTimeout(() => {
    store.dispatch({ type: LOADING_REMOVE_ALL_SUCCESS, page: "" });
  }, 2000);

  return {
    type: LOADING_FINISHED,
    page: page,
    msg: msg,
    success: success,
  };
}

export function removeLoadingSuccess(page: PageType): LoadingAction {
  return {
    type: LOADING_REMOVE_SUCCESS,
    page: page,
  };
}

export function removeAllLoadingSuccess(): LoadingAction {
  return {
    type: LOADING_REMOVE_ALL_SUCCESS,
    page: "",
  };
}

export function updateResponse(data): ToolBoxResponseAction {
  return {
    type: TOOLBOX_RESPONSE_UPDATE,
    ...data,
  };
}

export function clearFormErrors(): ToolBoxAction {
  return {
    type: CLEAR_FORM_ERRORS,
  };
}
