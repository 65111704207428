/* eslint-disable no-useless-computed-key */
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link as RouterLink, useParams } from "react-router-dom";
import DynamicForm from "../../component/Form/DynamicForm";
import { ATTEMPT_LOGIN } from "../../constants/constants";
import "../../i18n/config";
import { LoadingDataType } from "../../interfaces/LoadingData";
import { loadingState } from "../../store/Reducer";
import { getLoginFormStructure } from "./LoginFormStructure";
import "../../assets/scss/formElements/Checkbox.scss";

interface LoginFormProps {
  onSubmit: (user: Object | undefined) => void;
}

export default function LoginForm({ onSubmit }: LoginFormProps) {
  const formStructure = getLoginFormStructure();
  const loadingData: LoadingDataType = useSelector(loadingState);
  const { userType } = useParams();

  const isLoading = loadingData.loading.includes(ATTEMPT_LOGIN);
  const isSuccess = !!loadingData.success[ATTEMPT_LOGIN];

  const { t } = useTranslation();

  return (
    <Box className={"login-left-side"}>
      <Box pt={{ md: 12, sm: 6 }} className="main-title">
        <Typography variant="h1">{t("login.title")}</Typography>
      </Box>

      {userType && (
        <Typography variant="body1">
          <Box pt={5}>
            {userType === "hcp"
              ? t("login.hcp.subtitle")
              : t("login.patient.subtitle")}
          </Box>
        </Typography>
      )}
      <DynamicForm
        formStructure={formStructure}
        onSubmit={onSubmit}
        formClassName="login-form-items"
        loading={isLoading}
        success={isSuccess}
      />

      <RouterLink to="/forgot-password" className="link-holder">
        {t("login.forgot_password.label")}
      </RouterLink>

      <div className="login-page-register-container">
        <RouterLink to="/register" className="register-link">
          <div className="no-account-yet">
            {t("login.register.no_account_yet")}
          </div>
          <div className="register-text highlighted">
            {t("login.register.register")}
          </div>
        </RouterLink>
      </div>
    </Box>
  );
}
