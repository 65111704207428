import React from "react";
import { useSelector } from "react-redux";
import Footer from "../../component/Footer/Footer";
import Hero from "../../component/Hero/Hero";
import { ContentPageContainer } from "../../component/PageContainer/ContentPageContainer";
import { CoachingSection } from "../../component/SectionArticle/CoachingSection";
import { Section } from "../../component/SectionArticle/Section";
import SectionAccordion from "../../component/SectionArticle/SectionAccordion";
import SectionArticle from "../../component/SectionArticle/SectionArticle";
import SingleCardGallery from "../../component/SingleCard/SingleCardGallery";
import { DynamicPageType, PageType } from "../../interfaces/Pages";
import { contentState } from "../../store/Reducer";

export const CoachingToolPage = () => {
  const content: DynamicPageType = useSelector(contentState)[
    PageType.coachingTool
  ];

  return (
    <>
      <ContentPageContainer>
        {content && (
          <section className="lower-section">
            <Hero />

            <SectionArticle
              string_1={content.sections[0].subtitle}
              string_2={content.sections[0].title}
              desc={content.sections[0].body}
            />

            <SingleCardGallery
              title={content.sections[1].title}
              description={content.sections[1].subtitle}
              carousel={content.sections[1].carousel_items}
              hasDesc
              className={"fullwidth-transparent"}
            />

            <Section
              desc={content.sections[2].body}
              title={content.sections[2].title}
            />

            <SectionArticle
              string_2={content.sections[3].title}
              desc={content.sections[3].body}
              style={{ background: "transparent" }}
            />

            <CoachingSection modules={content.sections[4].gallery_items} />

            <SectionAccordion
              title={content.sections[5].title}
              body={content.sections[5].body}
            />
          </section>
        )}
      </ContentPageContainer>
      <Footer legal_number={content?.legal_number} />
    </>
  );
};
