import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Select } from "../../component/Select/Select";
import { Condition } from "../../interfaces/Condition";
import { DynamicPageType } from "../../interfaces/Pages";
import { authState, contentState } from "../../store/Reducer";
import React from "react";

export const Hero = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const uri = decodeURI(pathname.replace("/", ""));
  const conditions: Condition[] = useSelector(authState).conditions || [];

  const content: DynamicPageType = useSelector(contentState)[
    decodeURI(uri.replaceAll("%27", "'"))
  ];
  const hasSelect = uri.includes("your-condition");
  const baseUrl = "getting-started/your-condition/";

  let contentTitle = <h1>{content?.title}</h1>;
  if (content) {
    // @ts-ignore
    contentTitle = (<h1
      dangerouslySetInnerHTML={{ __html: content.title! }}
    ></h1>)
  }

  return (
    <div className={hasSelect ? "condition-select" : "hero-section-text"}>
      <div
        className={
          hasSelect
            ? "condition-select-inner-container"
            : "hero-section-text-inner-container"
        }
      >
        {contentTitle}
        {hasSelect && (
          <Select
            labels={conditions.map((c) => c.label.replace("%27", "'"))}
            values={conditions.map(
              (c) => baseUrl + c.condition.replaceAll("'", "%27")
            )}
            onChange={(value) => {
              history.push("/" + value + search);
            }}
            defaultValue={uri.replaceAll("'", "%27")}
          />
        )}
        {content?.subtitle && (
          <p className="subtitle">
            <span
              className={"condition-select-body"}
              dangerouslySetInnerHTML={{
                __html: content?.subtitle,
              }}
            ></span>
            {(content?.body && hasSelect) && (
              <div
                className={"condition-select-body"}
                dangerouslySetInnerHTML={{
                  __html: content?.body,
                }}
              ></div>
            )}
          </p>
        )}
        {(content?.body && !hasSelect) && (
          <div
            dangerouslySetInnerHTML={{
              __html: content?.body,
            }}
          ></div>
        )}
      </div>
      {content?.hero_image && (
        <div className="hero-section-image-container">
          <img
            className="hero-section-image"
            src={content?.hero_image?.url}
            alt={content?.hero_image?.alt}
          />
        </div>
      )}
    </div>
  );
};

export default Hero;
