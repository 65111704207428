/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Popup from "../component/Popup";
import {
  HCP,
  patient,
  RESTRICTED_PAGES_HCP,
  RESTRICTED_PAGES_PATIENT,
} from "../constants/constants";
import { PageType } from "../interfaces/Pages";
import { RestrictedPopupType } from "../interfaces/RestrictedPopupType";
import { authState, contentState } from "../store/Reducer";
import { useTranslation } from "react-i18next";

interface AuthLinkProps {
  to: string;
  children: React.ReactNode;
  className?: string;
  onClose?: () => void;
}

export const PrivateLink = ({
  to,
  children,
  className = "",
  onClose = () => {},
}: AuthLinkProps) => {
  const currentRole = useSelector(authState)?.user?.roles || [];
  const content = useSelector(contentState)[
    PageType.popup
  ] as RestrictedPopupType;
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const handleClick = () => {
    if (
      // user navigates to a non restricted page
      !(
        to.includes(RESTRICTED_PAGES_PATIENT) ||
        to.includes(RESTRICTED_PAGES_HCP)
      ) ||
      // user navigates to a patient restricted page and logged in as patient
      (currentRole.includes(patient) &&
        to.includes(RESTRICTED_PAGES_PATIENT)) ||
      // user navigates to an HCP restricted page and logged in as HCP
      (currentRole.includes(HCP) && to.includes(RESTRICTED_PAGES_HCP))
    ) {
      history.push(to);
      onClose();
    } else {
      // all other cases we get the popup
      setOpen(true);
    }
  };

  let path = "/login";

  return (
    <>
      <a onClick={handleClick} className={className}>
        {children}
      </a>
      <Popup
        open={open}
        onButton1={() => {
          setOpen(false);
        }}
        onButton2={() => {
          setOpen(false);
          onClose();
          history.push(path);
        }}
        className={"restricted-popup"}
        title={t("restrictedPopup.title")}
        contentText={t("restrictedPopup.content")}
        button1Text={t("restrictedPopup.button.cancel.label")}
        button2Text={t("restrictedPopup.button.submit.label")}
      />
    </>
  );
};
