import { Box } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ButtonWithLoader from "../../component/ButtonWithLoader";
import ConfirmDialog from "../../component/ConfirmDialog";
import DynamicForm from "../../component/Form/DynamicForm";
import { InverseButton } from "../../component/InverseButton";
import {
  ATTEMPT_DELETE_TOOLBOX_ITEM,
  ATTEMPT_EDIT_TOOLBOX_ITEM,
} from "../../constants/constants";
import useDisclosure from "../../hooks/useDisclosure";
import { PageType, SymptomEditPageType } from "../../interfaces/Pages";
import { ToolBoxDataType } from "../../interfaces/ToolBoxData";
import { contentState, loadingState } from "../../store/Reducer";
import {
  deleteToolBoxData,
  editToolBoxData,
  findEntity,
  getEditFormStructure,
  getLoadingData,
} from "../../util/util";
import { useTranslation } from "react-i18next";

interface ToolBoxEditFormProps {
  dataType: ToolBoxDataType;
  pageType: PageType;
  deleteFn: (data) => void;
  onClose?: () => void;
  modifyFn?: (data) => void;
  options?: object;
  id: string;
}

export default function ToolBoxEditForm({
  deleteFn,
  dataType,
  pageType,
  id,
  onClose,
  modifyFn,
}: ToolBoxEditFormProps) {
  const content: SymptomEditPageType = useSelector(contentState)[pageType];
  const { t } = useTranslation();
  const history = useHistory();
  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose,
  } = useDisclosure();

  const {
    isOpen: completeIsOpen,
    onOpen: completeOnOpen,
    onClose: completeOnClose,
  } = useDisclosure();

  const loadingDelete = getLoadingData(
    useSelector(loadingState),
    ATTEMPT_DELETE_TOOLBOX_ITEM
  );
  const loadingEdit = getLoadingData(
    useSelector(loadingState),
    ATTEMPT_EDIT_TOOLBOX_ITEM
  );

  const [payload, setPayload] = React.useState<any>();

  const editCallback = () => {
    if (onClose) onClose();
  };

  const deleteCallback = () => {
    if (onClose) onClose();
  };

  const formStructure = getEditFormStructure(
    findEntity(parseInt(id), dataType),
    dataType,
    content
  );

  const editGoal = (data) => {
    editToolBoxData(
      modifyFn,
      ATTEMPT_EDIT_TOOLBOX_ITEM,
      { ...data, id: parseInt(id) },
      dataType,
      history,
      true,
      editCallback
    );
  };

  const handleSubmit = (data) => {
    if (dataType === ToolBoxDataType.GOALS && data.field_goal_status) {
      setPayload(data);
      completeOnOpen();

      return;
    }

    editGoal(data);
  };

  const handleDelete = () => {
    deleteToolBoxData(
      deleteFn,
      ATTEMPT_DELETE_TOOLBOX_ITEM,
      id,
      dataType,
      history,
      true,
      deleteCallback
    );
  };

  const isCompleted =
    dataType === ToolBoxDataType.GOALS
      ? findEntity(parseInt(id), dataType)?.status === "1"
      : false;

  if (!formStructure) return null;

  return (
    <>
      <DynamicForm
        formStructure={formStructure}
        onSubmit={handleSubmit}
        formClassName={`edit-form toolbox ${dataType} edit`}
        renderButton={() => (
          <Box className="ActionButton__Wrapper">
            {!isCompleted && (
              <ButtonWithLoader
                type="submit"
                color="primary"
                aria-label={t("saveDialog.save")}
                loading={loadingEdit.isLoading}
                success={loadingEdit.isSuccess}
              >
                {t("saveDialog.save")}
              </ButtonWithLoader>
            )}

            <InverseButton
              onClick={onClose}
              aria-label={t("deleteDialog.cancel")}
            >
              {t("deleteDialog.cancel")}
            </InverseButton>
            {!isCompleted && (
              <InverseButton
                onClick={deleteOnOpen}
                aria-label={t("deleteDialog.delete")}
              >
                {t("deleteDialog.delete")}
              </InverseButton>
            )}
          </Box>
        )}
      />
      <ConfirmDialog
        onConfirmLabel={t("deleteDialog.delete")}
        onCancelLabel={t("deleteDialog.cancel")}
        onConfirm={handleDelete}
        onCancel={deleteOnClose}
        description={t("deleteDialog.confirmDelete")}
        open={deleteIsOpen}
        loading={loadingDelete.isLoading}
        success={loadingDelete.isSuccess}
      />
      <ConfirmDialog
        onConfirmLabel={t("saveDialog.save")}
        onCancelLabel={t("deleteDialog.cancel")}
        onConfirm={() => editGoal(payload)}
        onCancel={completeOnClose}
        description={t("goalPages.dialog.completeGoal")}
        open={completeIsOpen}
        loading={loadingEdit.isLoading}
        success={loadingEdit.isSuccess}
      />
    </>
  );
}
