import { Box } from "@material-ui/core";
import ButtonWithLoader from "./ButtonWithLoader";
import Dialog from "./Dialog";
import { InverseButton } from "./InverseButton";
import { useTranslation } from "react-i18next";

export interface DialogProps {
  onConfirmLabel?: string;
  onCancelLabel?: string;
  description?: string;
  onCancel?: () => void;
  onConfirm?: (data) => void;
  open: boolean;
  className?: string;
  loading: boolean;
  success: boolean;
}

export default function ConfirmDialog({
  onConfirmLabel,
  onCancelLabel,
  onCancel,
  onConfirm,
  description,
  className,
  open,
  loading,
  success,
}: DialogProps) {
  const classes = ["ConfirmDialog"];
  if (className) classes.push(className);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();

  return (
    <Dialog
      className={classes.join(" ")}
      open={open}
      contentText={description}
      renderContent={
        <Box className="ActionButton__Wrapper">
          <InverseButton onClick={onCancel}>
            {t("deleteDialog.cancel")}
          </InverseButton>
          <ButtonWithLoader
            color="primary"
            aria-label={onConfirmLabel}
            onClick={onConfirm}
            loading={loading}
            success={success}
          >
            {onConfirmLabel}
          </ButtonWithLoader>
        </Box>
      }
    />
  );
}
