export enum SectionType {
  simple = "simple",
  split = "split",
  reverse_split = "reverse split",
  carousel_items = "carousel_items",
  gallery_items = "gallery_items",
  column_items = "column_items",
  cta = "cta",
  cta_double = "cta_double",
  iframe = "iframe",
  white_card = "white_card",
  white_card_gallery = "white_card_gallery",
  white_card_split = "white_card_split",
  accordion = "accordion",
  text_left_symptom_carousel_right = "text_left_symptom_carousel_right",
  text_right_symptom_carousel_left = "text_right_symptom_carousel_left",
  content_with_tablet_image_left = "content_with_tablet_image_left",
  content_with_tablet_image_right = "content_with_tablet_image_right",
  content_with_notebook_image_left = "content_with_notebook_image_left",
  content_with_notebook_image_right = "content_with_notebook_image_right",
  content_with_mobile_image_left = "content_with_mobile_image_left",
  content_with_mobile_image_right = "content_with_mobile_image_right",
  blue_full_content_with_cta_below = "blue_full_content_with_cta_below",
  below_hero = "below_hero",
  split_content_with_tablet_image_left = "split_content_with_tablet_image_left",
  split_content_with_tablet_image_right = "split_content_with_tablet_image_right",
  split_content_with_notebook_image_left = "split_content_with_notebook_image_left",
  split_content_with_notebook_image_right = "split_content_with_notebook_image_right",
  split_content_with_mobile_image_left = "split_content_with_mobile_image_left",
  split_content_with_mobile_image_right = "split_content_with_mobile_image_right",
  content_left_multi_image_right = "content_left_multi_image_right",
  content_right_multi_image_left = "content_right_multi_image_left",
  content_with_simple_image_right = "content_with_simple_image_right",
  content_with_simple_image_left = "content_with_simple_image_left",
  text_left_symptom_carousel_accordion_right = "text_left_symptom_carousel_accordion_right",
  text_right_symptom_carousel_accordion_left = "text_right_symptom_carousel_accordion_left",
  text_top_symptom_carousel_bottom = "text_top_symptom_carousel_bottom",
  triple = "triple",
  content_top_multi_boxes_bottom = "content_top_multi_boxes_bottom",
  content_with_pros_and_cons_boxes = "content_with_pros_and_cons_boxes",
  content_with_pros_box = "content_with_pros_box",
}
