import { default as React, InputHTMLAttributes } from "react";

import "../../assets/scss/formElements/TextArea.scss";

interface CustomTextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  fullWidth?: boolean;
  noAnimation?: boolean;
  className: string;
  label: string;
}

const CustomTextArea = React.forwardRef<any, CustomTextAreaProps>(
  ({ className, fullWidth, ...props }, ref) => {
    let classes = [className];
    if (fullWidth) {
      classes = [...classes, "full-width"];
    }

    return (
      <div className="textarea-container">
        <textarea
          className={classes.join(" ") + " body-16"}
          ref={ref}
          {...props}
        />
        <label
          htmlFor={props.id}
          className={`${props.noAnimation ? "" : "animated-label"}`}
        >
          {props.label}
        </label>
      </div>
    );
  }
);

export default CustomTextArea;
