import React from "react";
import RightArrow from "../../resources/images/common/icon-feather-arrow-right.png";
import { PrivateLink } from "../../routing/PrivateLink";

export const CTASmall = ({ link = "", linkLabel = "" }) => {
  return (
    <div className="cta-small-container">
      <PrivateLink to={link}>
        <p className="cta-small body-18">{linkLabel}</p>
        {linkLabel && (
          <div className="arrow-container">
            <img src={RightArrow} alt="" />
          </div>
        )}
      </PrivateLink>
    </div>
  );
};
