import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { PageType } from "../../interfaces/Pages";
import PageContainer from "../PageContainer/PageContainer";

class ErrorBoundaryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // (this as any).props.history.push("/error");
    return { hasError: true };
  }

  render() {
    if ((this.state as any).hasError) {
      return (
        <PageContainer recContent={(this.props as any).content[PageType.error]}>
          <div></div>
        </PageContainer>
      );
    }

    return this.props.children;
  }
}

export default withRouter(
  connect((state) => ({ content: state.content }))(ErrorBoundaryPage)
);
