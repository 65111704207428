import { Box } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { Api } from "../../../api/Api";
import { PageType } from "../../../interfaces/Pages";
import { ToolBoxDataType } from "../../../interfaces/ToolBoxData";
import ToolBoxCreateForm from "../ToolBoxCreateForm";

interface GoalCreationFormProps {
  onClose?: () => void;
}

export default function GoalCreationForm({ onClose }: GoalCreationFormProps) {
  const { pathname } = useLocation();

  return (
    <Box>
      <ToolBoxCreateForm
        createFn={Api.createGoal}
        pageType={PageType.goalCreateCustom}
        dataType={ToolBoxDataType.GOALS}
        parentUrl={pathname}
        onClose={onClose}
      />
    </Box>
  );
}
