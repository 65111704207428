import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Api } from "../../../api/Api";
import "../../../assets/scss/Toolbox/Appointment/Appointment.scss";
import "../../../assets/scss/Toolbox/Reminder/Reminder.scss";
import ButtonWithLoader from "../../../component/ButtonWithLoader";
import Footer from "../../../component/Footer/Footer";
import PageContainer from "../../../component/PageContainer/PageContainer";
import ResponseHandler from "../../../component/ResponseHandler";
import { Select } from "../../../component/Select/Select";
import { Switch } from "../../../component/Switch/Switch";
import { Tabs } from "../../../component/Tabs/Tabs";
import WithLoader from "../../../component/WithLoader";
import { ATTEMPT_SET_REMINDER } from "../../../constants/constants";
import { GAEvent, GAEventCategory } from "../../../interfaces/GAEvent";
import { PageType } from "../../../interfaces/Pages";
import ReminderType from "../../../interfaces/ReminderType";
import { ToolBoxDataType } from "../../../interfaces/ToolBoxData";
import { loadingState } from "../../../store/Reducer";
import { finishedLoading, loading } from "../../../store/ToolBoxActions";
import mapDataToEntityFormat, {
  createGAEvent,
  getLoadingData,
  getWeekDays,
} from "../../../util/util";

const defaultObj = {
  field_tool_reminder_frequency: 1,
  field_tool_reminder_weekday: 1,
  isActive: false,
  id: -1,
};

export const ReminderPage = () => {
  const [reminder, setReminder] = useState<ReminderType>(defaultObj);
  const [activeTab, setActiveTab] = useState(0);
  const [hasReminder, setHasReminder] = useState(false);
  const currentLoadingState = useSelector(loadingState);
  const { isLoading, isSuccess } = getLoadingData(
    currentLoadingState,
    ATTEMPT_SET_REMINDER
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleIsActiveChange = () => {
    if (reminder.isActive && hasReminder) {
      handleSubmit("delete");
      setHasReminder(false);
    } 

    setReminder({ ...reminder, isActive: !reminder?.isActive });
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
    setReminder({
      ...reminder,
      field_tool_reminder_frequency: newValue === 0 ? 1 : 7,
    });
  };

  const handleSubmit = (action) => {
    dispatch(loading(ATTEMPT_SET_REMINDER));

    let apiCall = hasReminder ? Api.modifyReminder : Api.createReminder;

    if (action === "delete") {
      apiCall = Api.deleteReminder;
    }

    const data = {
      field_tool_reminder_frequency: reminder.field_tool_reminder_frequency.toString(),
      field_tool_reminder_weekday: reminder.field_tool_reminder_weekday.toString(),
    };

    apiCall(mapDataToEntityFormat(data, ToolBoxDataType.REMINDER))
      .then((resp) => {
        setHasReminder(action !== "delete");

        dispatch(finishedLoading(ATTEMPT_SET_REMINDER, resp.message, true));
        createGAEvent(
          GAEvent.FORM_SUBMISSION,
          GAEventCategory.REMINDER_CHANGED
        );
      })
      .catch((error) => {
        dispatch(finishedLoading(ATTEMPT_SET_REMINDER, error.message, false));
      });
  };

  const handleSelectChange = (value) => {
    setReminder({
      ...reminder,
      field_tool_reminder_weekday: value + 1,
    });
  };

  const getReminderSettings = () => {
    dispatch(loading(PageType.general));
    Api.getReminder()
      .then((resp) => {
        if (resp.success) {
          const data: ReminderType = resp.data;
          setReminder({
            field_tool_reminder_frequency: parseInt(
              data.field_tool_reminder_frequency.toString()
            ),
            field_tool_reminder_weekday: parseInt(
              data.field_tool_reminder_weekday.toString()
            ),
            id: -1,
            isActive: true,
          });
          setHasReminder(true);
          setActiveTab(
            parseInt(data.field_tool_reminder_frequency.toString()) === 1
              ? 0
              : 1
          );
        }
        dispatch(finishedLoading(PageType.general, resp.message, true));
      })
      .catch((error) => {
        dispatch(finishedLoading(PageType.general, error.message, false));
      });
  };

  useEffect(() => {
    getReminderSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reminderTabs = [
    t("reminder.tabs.daily.label"),
    t("reminder.tabs.weekly.label"),
  ];

  return (
    <>
      <PageContainer dataType={ToolBoxDataType.REMINDER}>
        <Box
          mt={{ sm: 0, lg: 9.5 }}
          mx={{ sm: 6, lg: 0 }}
          mb={{ sm: 24, lg: 0 }}
          className="reminder-container"
        >
          <Box
            mb={{ sm: 4, lg: 10 }}
            ml={{ sm: 1, lg: 0 }}
            className="switch-container"
          >
            <span className="reminder-switch-label switch-label">
              {t("reminder.toggleLabel")}
            </span>
            <Switch
              onChange={handleIsActiveChange}
              checked={reminder.isActive}
              showValue
            />
          </Box>
          {reminder.isActive && (
            <Box className="reminders-box">
              <Box
                className={`tab-container center-align ${
                  activeTab === 0 ? "daily" : "weekly"
                }`}
              >
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  labels={reminderTabs}
                />
              </Box>
              {activeTab === 1 && (
                <Box
                  mt={{ sm: 2, lg: 4 }}
                  className="starting-day-picker-wrapper"
                >
                  <Select
                    label={t("reminder.tabs.weekly.day")}
                    values={Array.from(Array(7).keys())}
                    labels={getWeekDays()}
                    onChange={handleSelectChange}
                    defaultValue={reminder.field_tool_reminder_weekday - 1}
                  />
                </Box>
              )}
              <Box className={"button-wrapper"}>
                <ResponseHandler />
                <div className="space-between-all button-container">
                  <ButtonWithLoader
                    onClick={handleSubmit}
                    loading={isLoading}
                    success={isSuccess}
                  >
                    {t("saveDialog.save")}
                  </ButtonWithLoader>
                </div>
              </Box>
            </Box>
          )}
        </Box>
      </PageContainer>
      <Footer legal_number={"GL-IDA-2100007"} />
    </>
  );
};
