import React from "react";
import "../../assets/scss/components/SectionArticle/SectionArticle.scss";
import { CTASmall } from "../CTA/CTASmall";

const SectionArticle = ({
  string_1 = "",
  string_2 = "",
  desc = "",
  isReverse = false,
  link = "",
  linkLabel = "",
  style = {},
}) => {
  let containerClasses = ["section-article-container"];
  if (isReverse) {
    containerClasses = [...containerClasses, "reverse"];
  }

  if (
    string_1.length === 0 &&
    string_2.length === 0 &&
    desc.length === 0 &&
    link.length === 0 &&
    linkLabel.length === 0
  ) {
    return <></>;
  }

  return (
    <div style={style} className={containerClasses.join(" ")}>
      <div className="section-article-inner-container">
        {(string_1 || string_2) && (
          <div className="section-article-title">
            {string_1 && (
              <div className="section-article-string-1">{string_1}</div>
            )}
            {string_2 && (
              <div
                className="section-article-string-2"
                dangerouslySetInnerHTML={{
                  __html: string_2,
                }}
              />
            )}
          </div>
        )}
        {(desc || linkLabel) && (
          <div className="section-article-description">
            {desc && (
              <div
                dangerouslySetInnerHTML={{
                  __html: desc,
                }}
              />
            )}
            {linkLabel && <CTASmall link={link} linkLabel={linkLabel} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionArticle;
