export const SectionIFrame = ({
  title = "",
  subtitle = "",
  desc = "",
  url = "",
}) => {
  return (
    <section>
      <h4 className="title">{title}</h4>
      <h4 className="font-weight-normal subtitle">{subtitle}</h4>
      <div className="body-18" dangerouslySetInnerHTML={{ __html: desc }} />
      <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
        <iframe
          src={
            url +
            "?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=1b34b2c3d0"
          }
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          title="title"
        ></iframe>
      </div>
    </section>
  );
};
