import { Box } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import React from "react";
import Footer from "../../component/Footer/Footer";
import { useTranslation } from "react-i18next";

interface ThankYouForRegisteringPageProps {
  breadcrumb: string;
}

export default function ThankYouForRegisteringPage(
  props: ThankYouForRegisteringPageProps
) {
  const { t } = useTranslation();

  return (
    <>
      <Container component="main" className={"thank-you-for-registering"}>
        <Box className={"thank-you-inner-container"}>
          <Box>
            <Typography component="h1" variant="h1">
              {t("register.thankYou.title")}
            </Typography>
          </Box>
          <Box className={"text-wrapper"}>
            <Typography variant="body1">
              {t("register.thankYou.checkEmail")}
            </Typography>
          </Box>
        </Box>
      </Container>
      <Footer legal_number={"Thank/You/Register"} />
    </>
  );
}
