import * as yup from "yup";
import { email, password } from "../../constants/constants";
import { FormComponent } from "../../interfaces/FormComponent";

export const getChangePasswordFormStructure = (t) => ({
  components: [
    {
      component: FormComponent.TextField,
      label: t("changePasswordFormStructure.fields.email"),
      name: "field_email",
      type: "text",
      placeholder: " ",
    },
    {
      component: FormComponent.TextField,
      label: t("changePasswordFormStructure.fields.password"),
      name: "field_password",
      autoComplete: "new-password",
      type: "password",
      placeholder: " ",
    },
    {
      component: FormComponent.TextField,
      label: t("changePasswordFormStructure.fields.passwordConfirm"),
      name: "field_confirm_password",
      autoComplete: "new-password",
      type: "password",
      placeholder: " ",
    },
  ],
  schema: yup.object().shape({
    field_email: email,
    field_password: password,
    field_confirm_password: yup
      .string()
      .required(t("form.messages.newPasswordConfirmRequired"))
      .oneOf([yup.ref("field_password"), null], t("form.messages.passwordsMustMatch")),
  }),
  title: t("changePasswordFormStructure.title"),
  submitLabel: t("changePasswordFormStructure.submit"),
});
