import { Box } from "@material-ui/core";
import { Api } from "../../../api/Api";
import { PageType } from "../../../interfaces/Pages";
import { ToolBoxDataType } from "../../../interfaces/ToolBoxData";
import ToolBoxEditForm from "../ToolBoxEditForm";

interface GoalEditFormProps {
  onClose?: () => void;
  id: string;
}

export default function GoalEditForm({ id, onClose }: GoalEditFormProps) {
  return (
    <Box>
      <ToolBoxEditForm
        id={id}
        pageType={PageType.goalEdit}
        dataType={ToolBoxDataType.GOALS}
        modifyFn={Api.modifyGoal}
        deleteFn={Api.deleteGoal}
        onClose={onClose}
      />
    </Box>
  );
}
