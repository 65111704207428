import * as yup from "yup";
import { email } from "../../constants/constants";
import { FormComponent } from "../../interfaces/FormComponent";
import { FormStructure } from "../../interfaces/FormStructure";

export const getForgotPasswordFormStructure = (t): FormStructure => {
  return {
    components: [
      {
        component: FormComponent.TextField,
        label: t("forgotPasswordFormStructure.fields.email"),
        name: "field_email",
        type: "email",
        autoComplete: "email",
        required: true,
        placeholder: " ",
      },
    ],
    schema: yup.object().shape({
      field_email: email,
    }),
    title: t("forgotPasswordFormStructure.title"),
    submitLabel: t("forgotPasswordFormStructure.submit"),
  };
};
