import React from "react";
import "../../assets/scss/components/CTA/CTA.scss";
import CTA from "./CTA";

interface CTADoubleProps {
  button_label1: string;
  button_label2: string;
  button_url1: string;
  button_url2: string;
  body1: string;
  body2: string;
  title1: string;
  title2: string;
}

const CTADouble = ({
  button_label1,
  button_label2,
  button_url1,
  button_url2,
  body1,
  body2,
  title1,
  title2,
}: CTADoubleProps) => {
  let buttonUrl1 = "#";
  let buttonUrl2 = "#";

  if (button_url1) {
    buttonUrl1 = button_url1;
  }

  if (button_url2) {
    buttonUrl2 = button_url2;
  }

  return (
    <section className="cta-double-container">
      <CTA
        button_label={button_label1}
        button_url={buttonUrl1}
        description={body1}
        title={title1}
      />
      <CTA
        button_label={button_label2}
        button_url={buttonUrl2}
        description={body2}
        title={title2}
      />
    </section>
  );
};

export default CTADouble;
