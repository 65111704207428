import { useSelector } from "react-redux";
import PageContainer from "../../component/PageContainer/PageContainer";
import { PageType } from "../../interfaces/Pages";
import { contentState } from "../../store/Reducer";

export const NotFoundPage = () => {
  const content = useSelector(contentState)[PageType.notFound];

  return (
    <PageContainer recContent={content}>
      <></>
    </PageContainer>
  );
};
