import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { ToolBoxDataType } from "../../../interfaces/ToolBoxData";
import goalsIcon from "../../../resources/images/toolbox/my-progress/goals.png";
import medicationIcon from "../../../resources/images/toolbox/my-progress/medications.png";
import reminderIcon from "../../../resources/images/toolbox/my-progress/notifications.png";

export const ToolBoxCard = ({ dataType, link, label, text, ctaText }) => {
  const getImg = () => {
    switch (dataType) {
      case ToolBoxDataType.GOALS:
        return goalsIcon;
      case ToolBoxDataType.MEDICATIONS:
        return medicationIcon;
      case ToolBoxDataType.REMINDER:
        return reminderIcon;
    }
  };

  const cardContent =
    dataType && dataType === "reminder" ? (
      <RouterLink to={link} className={"link-tile"}>
        <img src={getImg()} alt="graphic" height={71} width={94} />
        <h5>{label}</h5>
        {text && <div className="progress-tile-text">{text}</div>}
      </RouterLink>
    ) : (
      <>
        <img src={getImg()} alt="graphic" height={71} width={94} />
        <h5>{label}</h5>
        {text && <div className="progress-tile-text">{text}</div>}
        {ctaText && (
          <RouterLink to={link} className="progress-cta-container">
            <span>{ctaText}</span>
          </RouterLink>
        )}
      </>
    );

  return <div className="card-container">{cardContent}</div>;
};
