import { Button, SwipeableDrawer } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import "../../assets/scss/Cookie/Cookie.scss";
import { useQuery } from "../../util/util";
import { InverseButton } from "../InverseButton";

interface CookieSectionProps {
  open: boolean;
  toggleDrawer: (open: boolean) => void;
  onSubmit: () => void;
}

export default function CookieSection({
  open,
  toggleDrawer,
  onSubmit,
}: CookieSectionProps) {
  const { t } = useTranslation();
  let mobile = useQuery().get("mobile") === "true";

  return (
    mobile ? <></> : <SwipeableDrawer
      anchor={"top"}
      open={open}
      onClose={() => {
        toggleDrawer(false);
      }}
      onOpen={() => {
        toggleDrawer(true);
      }}
    >
      <div className={"cookie align"}>
        <div>
          <p>{t("cookiePopup.body")}</p>
        </div>
        <div className={"button-container"}>
          <InverseButton onClick={() => toggleDrawer(false)}>
            {t("cookiePopup.refuse")}
          </InverseButton>
          <Button onClick={onSubmit}>{t("cookiePopup.accept")}</Button>
        </div>
      </div>
    </SwipeableDrawer>
  );
}
