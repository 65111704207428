import React from "react";

const useDisclosure = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const onToggle = () => (isOpen ? onClose() : onOpen());

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return { isOpen, onToggle, onOpen, onClose };
};

export default useDisclosure;
