import { Box } from "@material-ui/core";
import { PageType } from "../../../interfaces/Pages";
import ToolBoxEditForm from "../ToolBoxEditForm";
import { Api } from "../../../api/Api";
import { ToolBoxDataType } from "../../../interfaces/ToolBoxData";

interface MedicationEditFormProps {
  onClose?: () => void;
  id: string;
}

export default function MedicationEditForm({
  id,
  onClose,
}: MedicationEditFormProps) {
  return (
    <Box>
      <ToolBoxEditForm
        id={id}
        options={{}}
        pageType={PageType.medicationLoggerEditMedication}
        dataType={ToolBoxDataType.MEDICATIONS}
        modifyFn={Api.modifyMedication}
        deleteFn={Api.deleteMedication}
        onClose={onClose}
      />
    </Box>
  );
}
