import { Box } from "@material-ui/core";
import React from "react";
import CarouselCards from "../CarouselCards/CarouselCards";

export const SectionCarousel = ({
  title,
  desc,
  carousel,
  hasText,
  hasDesc,
  hasBody = false,
  className = "",
}) => {
  return (
    <section className={className}>
      {(title || desc) && (
        <Box mt={{ sm: 1, lg: 0 }} pt={{ sm: 5, lg: 0 }}>
          {title && <h4>{title}</h4>}
          {desc && (
            <p className="body-18">
              <div
                dangerouslySetInnerHTML={{
                  __html: desc,
                }}
              />
            </p>
          )}
        </Box>
      )}
      {carousel[0]?.nid && (
        <CarouselCards
          cards={carousel}
          hasText={hasText}
          hasDesc={hasDesc}
          hasBody={hasBody}
        />
      )}
    </section>
  );
};
