import { Button, FormHelperText } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { contentState } from "../../store/Reducer";
import { useTranslation } from "react-i18next";

interface CTA {
  onClick: () => void;
  label?: string;
  limitReached?: string;
}

interface PageContainerType {
  children?: React.ReactNode;
  edit?: boolean;
  redirect?: string;
  recContent?: object;
  dataType?: string;
  cta?: CTA | null;
  buttonDisabled?: boolean;
}

export default function PageContainer({
  children,
  edit = false,
  redirect = "",
  recContent = {},
  dataType = "",
  buttonDisabled = false,
  cta,
}: PageContainerType) {
  const location = useLocation().pathname.replace("/", "");
  const content = useSelector(contentState)[location] || recContent;

  if (!content) {
    return <></>;
  }

  let { title } = content;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();

  const pageContainerContent = {
    "my-progress": {
      title: t("myProgress.title"),
      subtitle: t("myProgress.description"),
    },
    reminder: {
      title: t("reminder.title"),
      subtitle: t("reminder.subtitle"),
    },
    medications: {
      title: t("medicationPages.title"),
      subtitle: t("medicationPages.subtitle"),
    },
    goals: {
      title: t("goalPages.title"),
      subtitle: t("goalPages.subtitle"),
    },
    "forgot-password": {
      title: t("forgotPasswordFormStructure.title"),
    },
    "change-password": {
      title: t("changePasswordFormStructure.title"),
    },
  };

  let subtitle = content.subtitle;

  if (pageContainerContent[dataType]?.title) {
    title = pageContainerContent[dataType].title;
  }

  if (pageContainerContent[dataType]?.subtitle) {
    subtitle = pageContainerContent[dataType].subtitle;
  }

  return (
    <>
      <Container component="main" className={`${dataType}`}>
        <Box className={"page-outer-container"}>
          <Box className="page-header">
            <div className={"header-wrapper"}>
              <Box className={"page-inner-container"}>
                <Typography component="h1" variant="h1" className="main-title">
                  {title}
                </Typography>
              </Box>
              {subtitle && (
                <Box className="subtitle">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: subtitle,
                    }}
                  ></p>
                </Box>
              )}
              {edit && (
                <Box className={"editing"}>
                  <RouterLink to={redirect}>
                    <Button className="edit-button flex">
                      {t("medicationPages.button.addNew.label")}
                    </Button>
                  </RouterLink>
                </Box>
              )}
            </div>
            {cta && (
              <>
                <Button
                  className="button-create"
                  disabled={buttonDisabled}
                  onClick={() => cta.onClick()}
                >
                  {buttonDisabled ? cta.limitReached : cta.label}
                </Button>
              </>
            )}
          </Box>

          <Box className="content-container">{children}</Box>
        </Box>
      </Container>
    </>
  );
}
