import "../../../assets/scss/Section/StyledSectionImage/StyledSectionImage.scss";

export const StyledSectionImage = ({
  src = "",
  alt = "",
  className = "",
  height = "",
  width = "",
  right = true,
  imageTitle = "",
}) => {
  return (
    <div className={"styled-section-image"}>
        {(imageTitle && imageTitle.length > 10)  && (
            <div className="styled-section-image-title" dangerouslySetInnerHTML={{__html: imageTitle}}/>
        )}

        <div className="styled-section-image-inner">
            <div className="left-plus-sign plus-sign"/>
            <div className="right-plus-sign plus-sign"/>
            <div className="image-wrapper">
            <img
                src={src}
                alt={alt}
                className={className}
                height={height}
                width={width}
            />
        </div>
      </div>
      <div className="left-rectangle color-rectangle"></div>

      <div className="right-rectangle color-rectangle"></div>
    </div>
  );
};
