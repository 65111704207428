import { FormHelperText } from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToolBoxData } from "../interfaces/ToolBoxData";
import { toolBoxState } from "../store/Reducer";
import { TOOLBOX_RESPONSE_UPDATE } from "../store/ToolBoxActions";

interface ResponseHandlerProps {
  showError?: boolean;
  showSuccess?: boolean;
}

function ResponseHandler({
  showError = true,
  showSuccess = false,
}: ResponseHandlerProps) {
  const { error, msg }: ToolBoxData = useSelector(toolBoxState);
  const dispatch = useDispatch();

  useEffect(
    () => dispatch({ type: TOOLBOX_RESPONSE_UPDATE, msg: "", error: "" }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      {showError && error && (
        <FormHelperText id="component-helper-text" error>
          {error && error === "[]" ? msg : error}
        </FormHelperText>
      )}
      {showSuccess && (
        <FormHelperText id="component-helper-text">{msg}</FormHelperText>
      )}
    </>
  );
}

export default ResponseHandler;
