import { Tab as MuiTab, Tabs as MuiTabs } from "@material-ui/core";
import React from "react";
import "../../assets/scss/components/Tabs/Tabs.scss";

interface TabsProps {
  onChange?: (event: React.ChangeEvent<{}>, value: number) => void;
  value?: number;
  labels?: string[];
  className?: string;
}

export const Tabs = ({
  value,
  onChange,
  labels = [],
  className,
}: TabsProps) => {
  const classes = ["Tabs"];
  if (className) classes.push(className);

  return (
    <MuiTabs
      className={classes.join(" ")}
      value={value}
      onChange={onChange}
      aria-label="tabs"
      textColor={"primary"}
      TabIndicatorProps={{
        style: {
          display: "none",
        },
      }}
    >
      {labels.map((label, i) => (
        <MuiTab label={label} key={i} />
      ))}
    </MuiTabs>
  );
};
